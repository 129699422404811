/*
 * @Author: liuhanchuan 
 * @Date: 2021-12-20 14:39:18 
 * @Last Modified by: liuhanchuan
 * @Last Modified time: 2021-12-29 15:25:29
 */
<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="388px"
        :before-close="beforeClose"
        append-to-body
        :show-close="false"
        center
    >
        <div class="connectUs_box">
            <h2>视频解析已用完</h2>
            <img src="@/assets/images/homePage/service.jpg" alt="">
            <span>联系我们吧</span>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data: function() {
        return {
            dialogVisible: false,
        }
    },
    mounted() {
        this.$bus.$on("connectUs", () => {
            this.openDialog()
        });
    },
    beforeDestroy () {
        this.$bus.$off('connectUs');
    },
    methods: {
        openDialog() {
            this.dialogVisible = true
        },
        beforeClose() {
            this.dialogVisible = false
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    border-radius: 12px;
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 36px 36px 24px;
        .connectUs_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            > h2 {
                color: #1D1D1D;
                font-size: 22px;
                // margin-bottom: 36px;
            }
            > img {
                width: 284px;
                margin-bottom: 12px;
            }
            > span {
                font-size: 20px;
                color: #313131;
            }
        }
    }
}
</style>
