<template>
    <el-dialog
      :visible.sync="isDialogVisible"
      title="素材中心"
      width="1110px"
      top="8vh"
      :closeOnClickModal="false"
      :before-close="videoBeforeClose"
      append-to-body
    >
        <div class="video_list" v-loading="loading">
            
            <div class="filter">
                <el-tabs v-model="activeTab" @tab-click="handleTabClick">
                    <el-tab-pane label="本地视频" name="本地视频"></el-tab-pane>
                    <!-- <el-tab-pane label="视频素材" name="视频素材"></el-tab-pane> -->
                    <el-tab-pane label="推荐视频" name="推荐视频"></el-tab-pane>
                    <el-tab-pane label="行情回顾" name="行情回顾"></el-tab-pane>
                    <el-tab-pane label="财务报告" name="财务报告"></el-tab-pane>
                    <el-tab-pane label="舆情日报" name="舆情日报"></el-tab-pane>
                    <el-tab-pane label="个股诊断" name="个股诊断"></el-tab-pane>
                    <el-tab-pane label="产业链" name="产业链"></el-tab-pane>
                </el-tabs>
                <div class="inputSearch" v-if="['本地视频', '推荐视频', '产业链'].indexOf(activeTab) !== -1">
                    <el-input placeholder="输入要查找的内容" v-model="keyword" @change="searchKeyword" clearable>
                    <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
                    </el-input>
                </div>
                <el-select
                    v-if="['行情回顾', '财务报告', '舆情日报', '个股诊断'].indexOf(activeTab) !== -1"
                    v-model="keyword"
                    placeholder="输入要查找的内容"
                    filterable
                    remote
                    clearable
                    :remote-method="searchStock"
                    :loading="selectLoading"
                    @change="changeValue"
                >
                    <el-option
                        v-for="item in stockArr"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="video_box" v-loading="addloading">
                <div class="v_video" v-for="(item, index) in videoList" :key="index">
                    <div class="v_img">
                        <img :src="item.picUrl" alt="" />
                        <div class="v_operations">
                            <span @click="generateVideo(item)">生成视频</span>
                        </div>
                    </div>
                    <div class="v_title" :title="item.videoName">
                        {{ item.videoName }}
                    </div>
                </div>
            </div>
            <div class="pagination" v-show="total > pageSize">
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { getStock } from '@/api/upload'

export default {
    data: function() {
        return {
            isDialogVisible: false,
            activeTab: "本地视频",
            model_id: undefined,
            loading: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            keyword: '',
            selectLoading: false,
            stockArr: [], // 股票列表
            addNum: 0,
            addloading: false,
            videoList: [], //互动视频列表
        }
    },
     mounted() {
        // 接收批量上传接口上传成功后返回的值
        this.$bus.$on("uploadSuccess", async (from, data) => {
            if (from === 'material_video') {
                this.branchVideoCreate(data)
            }
        });
    },
    beforeDestroy () {
        this.$bus.$off('uploadSuccess');
    },
    methods: {
        show() {
            this.isDialogVisible = true
            this.getVideoList();
        },
        videoBeforeClose() {
            if (this.addNum) {
                this.$emit('getInteractVideoList')
            }
            Object.assign(this.$data, this.$options.data());
        },
        handleTabClick() {
            switch (this.activeTab) {
            case '行情回顾':
                this.model_id = 9
                break;
            case '财务报告':
                this.model_id = 200
                break;
            case '舆情日报':
                this.model_id = 199
                break;
            case '个股诊断':
                this.model_id = 14
                break;
            case '产业链':
                this.model_id = 100
                break;
            default:
                this.model_id = undefined
                break;
            }
            this.initData()
            this.getVideoList()
        },
        initData() {
            this.pageNum = 1
            this.total = 0
            this.videoList = []
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getVideoList()
        },
        // 本地视频搜索
        searchKeyword() {
            this.pageNum = 1;
            this.getVideoList()
        },
        changeValue() {
            this.pageNum = 1;
            this.getVideoList()
        },
        searchStock(value) {
            this.selectLoading = true
            getStock({
                key: value,
                type: 0
            }).then((res) => {
                this.selectLoading = false
                if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
                    this.handleStockList(res.vStockItem)
                }
            })
        },
        handleStockList(value) {
            if (value && value instanceof Array && value.length > 0) {
                this.stockArr = value.map((item) => {
                    return {
                        label: item.sCHNShortName,
                        value: item.sDtSecCode.slice(4),
                    }
                })
            }
        },
        //获取列表
        async getVideoList() {
            this.loading = true
            try {
                if (this.activeTab === '本地视频') {
                let data = await this.API.materialCenter.branchVideoList({
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    videoName: this.keyword || undefined
                });
                this.videoList = data.data.list;
                this.total = data.data.total;
                } else {
                let data = await this.API.materialCenter.videoresultsearch({
                    page: this.pageNum,
                    size: this.pageSize,
                    model_id: this.model_id,
                    keyword: this.keyword || undefined
                })
                this.videoList = data.data.map((item) => {
                    return {
                        picUrl: item.video_image_url,
                        videoName: item.stock_name || item.article_title,
                        videoUrl: item.article_content
                    }
                })
                this.total = data.counts
                }
                this.loading = false
            } catch (error) {
                this.loading = false
            }
        },
        // 生成互动视频
        generateVideo(item) {
            this.addloading = true
            this.API.interactVideo.addVideoToMultiMedia({
                picUrl: item.picUrl,
                publishStatus: 0,
                videoName: item.videoName,
                videoUrl: item.videoUrl,
            }).then((res) => {
                if (res && res instanceof Object && res.code === 200) {
                    this.$message.success('添加成功')
                    this.addNum += 1
                } else {
                    this.$message.error('添加失败')
                }
                this.addloading = false
            })
        }
        // 调用上传视频接口
        // async branchVideoCreate(value) {
        //     this.loading = true
        //     const params = value.map((item) => {
        //         return {
        //             smallPicUrl: item.smallPicUrl, // 可以不传，暂时没意义
        //             picUrl: item.picUrl,
        //             videoName: item.fileName,
        //             videoUrl: item.downUrl
        //         }
        //     })
        //     const data = await this.API.materialCenter.branchVideoCreate(params);
        //     if (data && data instanceof Object && data.code === 200) {
        //         this.getVideoList()
        //     }
        // },
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
    padding: 20px 20px 10px !important;
    color: #252525;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .el-dialog__headerbtn {
        top: 20px !important;
        background-color: inherit;
    }
}
/deep/ .el-dialog__body {
    padding-top: 20px;
    .video_list {
        min-height: 710px;
        .filter {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .inputSearch {
                width: 22%;
                margin: 12px 14px;
            }
        }
    }
}
/deep/ .el-select {
    margin: 12px 14px;
    width: 22%;
}
/deep/ .el-tabs {
    padding-left: 24px;
    .el-tabs__header {
    margin-bottom: 10px;
    }
    .el-tabs__active-bar {
    width: 18px !important;
    margin-left: 18px;
    height: 4px;
    }
    .el-tabs__item {
    color: #8d8da7;
    font-weight: bold;
    height: 36px;
    }
    .el-tabs__item.is-active {
    color: #252525;
    font-weight: bold;
    }
    .el-tabs__nav-wrap::after {
    height: 0;
    }
}
 .video_list {
     .video_box {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        
        .v_video {
            width: 198px;
            margin-right: 14px;
            margin-bottom: 14px;
            .v_img {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 300px;
                width: 100%;
                overflow: hidden;
                border-radius: 6px;
                background: #f0f0f0;
                > img {
                    width: 100%;
                    height: auto;
                }
                &:hover {
                    .v_operations {
                        opacity: 1;
                    }
                }
                .v_operations {
                    width: 100%;
                    text-align: right;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    padding: 7px 12px;
                    color: #fff;
                    span {
                        font-size: 14px;
                        height: 28px;
                        line-height: 28px;
                        cursor: pointer;
                        margin-left: 14px;
                        background: rgba(0, 0, 0, 0.32);
                        padding: 2px 4px;
                        border-radius: 4px;
                        &:hover {
                            color: #FF7500;
                        }
                    }
                }
            }
            .v_title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #252525;
                margin-top: 8px;
            }
        }
     }
 }
.pagination {
  display: flex;
  justify-content: center;
}
</style>