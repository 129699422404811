<template>
  <div class="interact_page">
    <header>
      <el-radio-group v-model="type" size="small" @change="changeType">
        <el-radio-button label="all">全部</el-radio-button>
        <el-radio-button label="isPublish">已发布</el-radio-button>
        <el-radio-button label="noPublish">未发布</el-radio-button>
      </el-radio-group>
      <div class="inputSearch">
        <el-input placeholder="请输入标题关键字进行搜索" v-model="title" @change="searchTitle">
        <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </header>
    <ul class="interact_video_list" v-loading="loading">
      <li :class="['li_add', userInfo.remainTime > 0 || !userInfo.whiteStatus ? '' : 'disabled']">
        <img src="@/assets/images/interact_video/ico_add.svg" alt="">
        <span>添加视频</span>
        <p>建议视频比例 9:16</p>
        <div>
          <span @click="openUploadModal">本地上传</span>
          <span @click="openMaterialCenter">素材中心</span>
        </div>
      </li>
      <li v-for="(item, index) in videoList" :key="index">
        <div>
            <div class="v_img" @click="edit(item)">
              <img :src="item.picUrl" alt="" :class="item.jobStatus" />
              <div class="v_operations" v-if="item.jobStatus == 'Success'">
                <span @click.stop="issue(item)" v-if="item.publishStatus">取消发布</span>
                <span @click.stop="spreadVideo(item)" v-if="item.publishStatus">分享</span>
                <span @click.stop="del(item)">删除</span>
              </div>
              <div class="v_wait" v-if="item.jobStatus != 'Success' && item.jobStatus != 'Fail'">
                请稍等，正在生成时间轴
              </div>
              <div class="v_wait" v-if="item.jobStatus == 'Fail'">生成时间轴失败</div>
            </div>
            <div class="v_title">
              <!-- <span :title="item.title">{{ item.title }}</span> -->
              <EditName :value="item.title" :item="item" @updataList="getInteractVideoList" />
              <div class="title_status">
                <!-- <span>{{ $moment(item.updateTime).format('YYYY-MM-DD') }}</span> -->
                <span>{{ item.publishTime }}</span>
                <span v-if="item.publishStatus" class="label">已发布</span>
              </div>
            </div>
        </div>
      </li>
    </ul>
    <div class="pagination" v-show="total > pageSize">
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <MaterialDialog ref="material" @getInteractVideoList="updateInteractVideoList"/>
    <SpreadVideo ref="spreadVideo" @openSpreadDialog="openSpreadDialog"/>
    <SpreadDialog ref="spreadDialog" />
    <!-- 7天试用用户，解析视频次数超过30次，提示使用完毕弹框 -->
    <ConnectUsDialog />
  </div>
</template>

<script>
import { sleep } from "@/utils/utils";
import EditName from './EditName.vue';
import MaterialDialog from './materialDialog/index.vue'
import ConnectUsDialog from './connectUsDialog/index.vue'
import SpreadVideo from './spreadVideo/index.vue'
import SpreadDialog from './spreadVideo/SpreadDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: "InteractVideo-List",
  data() {
    return {
      type: 'all',
      title: '',
      loading: false,
      pageSize: 15,
      pageNum: 1,
      total: 0,
      videoList: [], //互动视频列表
      isUpload: false, // 是否上传过视频
      editVideoId: "", //当前编辑的互动视频id
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
    };
  },
  components: {
    EditName,
    MaterialDialog,
    ConnectUsDialog,
    SpreadVideo,
    SpreadDialog
  },
  computed: {
    ...mapGetters('videoList', {
        currentPageNum: 'currentPageNum',
    }),
  },
  created() {
    this.pageNum = this.currentPageNum
    this.getInteractVideoList();
    // 当上传互动视频后，互动视频列表三十秒刷新一次
    setInterval(async() => {
      if (this.isUpload) {
        const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          title: this.title || undefined,
        };
        const data = await this.API.interactVideo.videoList(params);
        this.videoList = data.data.list;
        this.total = data.data.total;
      }
    }, 30000)
  },
   mounted() {
    // 接受上传组件更新数据的请求
    this.$bus.$on('upDataList', async (from, data) => {
      await this.uploadVideoByInterface(data)
      this.loading = true
      this.isUpload = true
      // 上传视频，返回第一页
      this.pageNum = 1
      this.title = ''
      await sleep(2000)
      this.getInteractVideoList()
    })
  },
  beforeDestroy () {
    this.$bus.$off('upDataList');
  },
  methods: {
    // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getInteractVideoList()
    },
    // pageSize切换
    handleSizeChange(val) {
        this.pageNum = 1
        this.pageSize = val
        this.getInteractVideoList()
    },
    // 类型切换
    changeType() {
       this.pageNum = 1
       this.title = ''
       this.getInteractVideoList()
    },
    // 关键词查询
    searchTitle() {
      this.pageNum = 1;
      this.getInteractVideoList();
    },
    // 素材中心上传视频
    updateInteractVideoList() {
      this.pageNum = 1;
      this.title = ''
      this.getInteractVideoList();
    },
    //获取列表
    async getInteractVideoList() {
      this.loading = true
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        title: this.title || undefined,
        publishStatus: this.type === 'all' ? undefined : this.type === 'isPublish' ? 1 : 0
      };
      try {
        const data = await this.API.interactVideo.videoList(params);
        this.videoList = data.data.list;
        this.total = data.data.total;
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    //获取文章内容
    async getVideoDetail(id) {
      return new Promise(async (resolve, reject) => {
        const params = {
          id: id
        };
        const { data } = await this.API.interactVideo.videoDetail(params);
        resolve(data);
      });
    },
    // 取消发布
    async issue(item) {
      const res = await this.API.interactVideo.publishVideo({
        id: item.id
      });
      if (res && res instanceof Object && res.code === 200) {
        this.$message.success('取消发布！')
        this.getInteractVideoList()
      } else {
        this.$message.error('取消发布失败！')
      }
    },
    spreadVideo(item) {
      this.$refs.spreadVideo.open(item);
    },
    openSpreadDialog(item) {
      this.$refs.spreadDialog.open(item);
    },
    // 编辑
    edit(item) {
      if (item.jobStatus === "Success") {
        this.$router.push({
            path: "/interact_video/edit",
            query: {
                videoItem: JSON.stringify(item)
            }
        });
        this.$store.commit('videoList/setCurrentPageNum', this.pageNum)
      } else {
        this.$message.warning('正在生成时间轴, 请稍候')
      }
    },
    //视频列表-删除
    del(item) {
      this.$confirm("是否确认删除此互动视频?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const params = {
          id: item.id
        };
        const data = await this.API.interactVideo.videoDelete(params);
        if (data.code == 200) {
          // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
          if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
            this.getInteractVideoList()
          } else {
            this.pageNum -= 1
            this.getInteractVideoList()
          }
        } else {
          this.$message.error("删除失败" + JSON.stringify(data));
        }
      });
    },
    // 上传视频
    openUploadModal() {
      if(this.userInfo.remainTime > 0 || !this.userInfo.whiteStatus) {
        this.$bus.$emit('openUploadFile',{from:'videoList',type:'video'})
      }
    },
    // 调用上传视频素材的接口
    uploadVideoByInterface(value) {
      return new Promise(async (resolve) => {
        const params = value.map((item) => {
            return {
                picUrl: item.picUrl,
                title: item.fileName,
                downUrl: item.downUrl,
                size: item.size
            }
        })
        await this.API.materialCenter.uploadVideoByInterface(params);
        resolve()
      })
    },
    openMaterialCenter() {
      this.$refs.material.show()
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
