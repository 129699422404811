<template>
  <!-- 文章分享 -->
  <el-dialog
    :visible.sync="isShow"
    title="视频分享参数设置"
    width="920px"
    :closeOnClickModal="false"
    :before-close="beforeClose"
    append-to-body
    center
  >
    <div class="dlg-content">
      <el-form ref="form" :model="form" label-width="64px">
        <el-form-item label="标题:">
          <el-input v-model="form.title" maxlength="20" placeholder="视频分享标题"></el-input>
        </el-form-item>
        <el-form-item label="描述:">
          <el-input v-model="form.summary" maxlength="100" placeholder="视频分享描述"></el-input>
        </el-form-item>
        <el-form-item label="配图:">
          <div class="uploadCode" @click="importWord">
            <img :src="form.image" alt="" v-if="form.image" />
            <i v-else class="el-icon-plus"></i>
            <span v-show="form.image">
              <i class="el-icon-delete" @click.stop="deleteImg"></i>
            </span>
          </div>
        </el-form-item>
        <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif" />
      </el-form>
      <CropperDialog ref="cropper" @receiveData="setUploadUrl" />
    </div>
    <div class="dlg-footer" slot="footer">
      <span class="dlg-btn-cancel" @click="beforeClose">取消</span>
      <span class="dlg-btn-ok" @click="okEvent">确认</span>
    </div>
  </el-dialog>
</template>

<script>
import CropperDialog from '@/components/cropperDialog'

export default {
  data() {
    return {
      isShow: false, // 是否显示
      form: {
        title: "",
        summary: "",
        image: "",
      },
      spreadId: undefined,
    };
  },
  components: {
    CropperDialog
  },
  methods: {
    open(value) {
        if (value && value instanceof Object) {
          const { video } = JSON.parse(value.updateContent)
          this.form.title = value.shareTitle || value.title || ''
          this.form.summary = value.shareDesc || video.sDes || ''
          this.form.image = value.shareImage || value.picUrl || ''
          this.spreadId = value.id || undefined
        }
        this.isShow = true;
    },
    // 关闭弹框
    beforeClose() {
      Object.assign(this.$data, this.$options.data());
    },
    checked() {
      if (this.form.image == "") {
        this.$message.warning("请输入配图");
        return false;
      }
      this.form.title = this.form.title.trim();
      if (this.form.title == "") {
        this.$message.warning("请输入标题");
        return false;
      }
      if (this.form.title.length < 5) {
        this.$message.warning("标题长度在5～20之间！");
        return false;
      }

      this.form.summary = this.form.summary.trim();
      if (this.form.summary == "") {
        this.$message.warning("请输入分享语");
        return false;
      }
      if (this.form.summary.length < 5) {
        this.$message.warning("分享语长度在5～100之间！");
        return false;
      }
      return true;
    },
    okEvent() {
      const _that = this
      if (_that.checked()) {
        _that.API.interactVideo.videoUpdate({
          id: _that.spreadId,
          shareTitle: _that.form.title,
          shareDesc: _that.form.summary,
          shareImage: _that.form.image,
        }).then((res) => {
          if (res && res instanceof Object && res.code === 200) {
            _that.$message.success('设置分享成功')
            _that.$emit('openSpreadDialog', _that.spreadId)
            _that.beforeClose()
          } else {
            _that.$message.error('设置分享失败')
          }
        })
      }
    },
    importWord() {
      this.$refs.fileInput.click();
    },
    batchImport(e) {
      const [file] = e.target.files;
      const checkSize = this.imgBeforeUpload(file);
      if (checkSize) {
         this.$refs.cropper.openDialog(file)
      }
      // 清空input的值
      this.$refs.fileInput.value = "";
    },
    //图片上传前，检查大小是否超出5M
    imgBeforeUpload(file) {
      const isFileSizeFit = file.size / 1024 / 1024 < 5;
      if (!isFileSizeFit) {
        this.$message.error("图片大小不能超过 5MB!");
      }
      return isFileSizeFit;
    },
    setUploadUrl(url) {
        this.form.image = url;
    },
    deleteImg() {
      this.form.image = "";
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  color: #252525;
  font-weight: bold;
  .el-dialog__headerbtn {
    top: 20px !important;
    background-color: inherit;
  }
}
.uploadCode {
  width: 128px;
  height: 128px;
  background: #fbfdff;
  color: #8c939d;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #c0ccda;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  &:hover {
    border-color: #ff9226;
    > span {
      opacity: 1;
    }
  }
  > img {
    max-height: 100%;
    max-width: 100%;
  }
  > span {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(29, 29, 29, 0.7);
    opacity: 0;
    color: #fff;
    cursor: pointer;
  }
}
.dlg-btn-ok {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: #ff9226;
  box-shadow: 0px 3px 6px rgba(255, 146, 38, 0.4);
  opacity: 1;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.dlg-btn-cancel {
  width: 140px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #ff9226;
  opacity: 1;
  border-radius: 6px;
  display: inline-block;
  text-align: center;
  margin-right: 60px;
  cursor: pointer;
  color: #ff9226;
}
</style>
