<template>
  <el-dialog
    :visible.sync="isShow"
    title="视频分享"
    width="920px"
    :closeOnClickModal="false"
    :before-close="beforeClose"
    append-to-body
    center
  >
    <div class="dlg-content">
      <el-form ref="form" label-width="84px">
        <el-form-item label="链接地址:">
          <el-input v-model="url" readonly></el-input>
          <el-button type="primary" @click="copy(url)">复制链接</el-button>
        </el-form-item>
        <el-form-item label="二维码:">
          <div class="qrBox" ref="qrBox">
            <div ref="qrCode"></div>
          </div>
          <el-button type="primary" @click="copyCode">复制二维码</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="dlg-footer" slot="footer">
      <el-button type="primary" @click="beforeClose">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import QRCode from "qrcodejs2";
import { copyText } from "@/utils/utils.js";
export default {
  data: function() {
    return {
      url: "https://dtinteraction.mdengta.com/dengYingSingle/",
      isShow: false,
    };
  },

  methods: {
    open(value) {
      this.isShow = true;
      this.url = `${this.url}?id=${value}&dt_from=web`;
      this.createQrCode();
    },
    createQrCode() {
      this.$nextTick(() => {
        this.$refs.qrCode.innerHTML = "";
        new QRCode(this.$refs.qrCode, {
          text: this.url,
          width: 180,
          height: 180,
          colorDark: "#000",
          colorLight: "#fff"
        });
      });
    },
    // 关闭弹框
    beforeClose() {
      Object.assign(this.$data, this.$options.data());
    },
    copy(value) {
      copyText(value).then(() => {
        this.$message.success({ message: "地址复制成功" });
      });
    },
    async copyCode() {
      const data = await fetch(this.$refs.qrBox.querySelectorAll("img")[0].src);
      const blob = await data.blob();
      await navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
      this.$message.success({ message: "二维码复制成功" });
    },
  }
};
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
  padding: 20px 20px 10px !important;
  color: #252525;
  font-weight: bold;
  .el-dialog__headerbtn {
    top: 20px !important;
    background-color: inherit;
  }
}
/deep/ .el-form-item__content {
  display: flex;
  .el-button--primary {
    margin-left: 24px;
    align-self: center;
  }
}
.dlg-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
